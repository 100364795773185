import actions from "./actions";

let initState = () => ({
  loading: false,
  isShowExpireAlert: false,
  errors: {},
});

export default function resetPasswordReducer(state = initState(), action) {
  let { type, payload } = action;
  switch (type) {
    // For request reset password
    case actions.REQUEST_RESET_PASSWORD:
      return { ...state, loading: true };
    case actions.REQUEST_RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false, errors: {} };
    case actions.REQUEST_RESET_PASSWORD_FAIL:
      return { ...state, loading: false, errors: payload.error };

    // For reset password
    case actions.RESET_PASSWORD:
      return { ...state, loading: true };
    case actions.RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false, errors: {} };
    case actions.RESET_PASSWORD_FAIL:
      return { ...state, loading: false, errors: payload.error };
    default:
      return state;
  }
}
