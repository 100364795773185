const actions = {
  GET_COMPANY_HOLIDAYS: "companyHoliday/GET_COMPANY_HOLIDAYS",
  GET_COMPANY_HOLIDAYS_SUCCESS: "companyHoliday/GET_COMPANY_HOLIDAYS_SUCCESS",
  GET_COMPANY_HOLIDAYS_FAIL: "companyHoliday/GET_COMPANY_HOLIDAYS_FAIL",
};

export default actions;

export const getCompanyHolidays = (filters) => ({
  type: actions.GET_COMPANY_HOLIDAYS,
  payload: { filters },
});