import { call, put, takeLatest } from "redux-saga/effects";

import firebase from "firebase/app";
import "firebase/auth";

import actions from "./actions";
import userApi from "../../api/usersApi";

import { notification } from "antd";
import cons from "../../constants";

function* getUsersByFilters(data) {
  try {
    const allUser = yield call(
      _getUsersByFilters.bind(this, data.payload.filters),
    );

    yield put({
      type: actions.GET_USERS_BY_FILTERS_SUCCESS,
      payload: { allUser },
    });
  } catch (error) {
    yield put({
      type: actions.GET_USERS_BY_FILTERS_FAIL,
      payload: { error },
    });
    notification.error({ message: "Get list member failed!" });
  }
}

function _getUsersByFilters(filters) {
  return userApi.getAllUsers(filters).then((response) => {
    return response;
  });
}

function* getAllUser() {
  try {
    const allUser = yield call(_getAllUser.bind(this));

    yield put({
      type: actions.GET_ALL_USER_SUCCESS,
      payload: { allUser },
    });
  } catch (error) {
    yield put({
      type: actions.GET_ALL_USER_FAIL,
      payload: { error },
    });
    notification.error({ message: "Get list member failed!" });
  }
}

function _getAllUser() {
  return userApi.getAllUsers().then((response) => {
    return response;
  });
}

function* getUserInfoById(data) {
  try {
    const userInfoData = yield call(_getUserInfoById.bind(this, data.payload));

    yield put({
      type: actions.GET_USER_INFO_BY_ID_SUCCESS,
      payload: { userInfoData },
    });
  } catch (error) {
    yield put({
      type: actions.GET_USER_INFO_BY_ID_FAIL,
      payload: { error },
    });
    notification.error({ message: "Get user information failed!" });
  }
}

function _getUserInfoById(payload) {
  return userApi.getUserInfoById(payload.userId).then((response) => {
    return response;
  });
}

function* updatePassword(data) {
  try {
    yield call(_updatePassword.bind(this, data.payload));
    yield put({
      type: actions.UPDATE_PASSWORD_SUCCESS,
    });
    data.payload.callBack();
  } catch (error) {
    yield put({
      type: actions.UPDATE_PASSWORD_FAIL,
      payload: { error },
    });
    notification.error({ message: "Update password failed!" });
  }
}

async function _updatePassword(payload) {
  await firebase
    .auth()
    .signInWithEmailAndPassword(payload.email, payload.oldPassword);
  const user = firebase.auth().currentUser;
  await user.updatePassword(payload.newPassword);
}

function* editUser(data) {
  const isUpdateUser = data.payload.type === cons.ADD_OR_UPDATE_USER.UPDATE;
  try {
    // To update user
    if (isUpdateUser) {
      yield call(_updateUser.bind(this, data.payload.body));
    } else {
      // Add new user
      yield call(_addNewUser.bind(this, data.payload.body));
    }
    yield put({
      type: actions.EDIT_USER_SUCCESS,
    });
    notification.success({
      message: `${isUpdateUser ? "Update" : "Add"} user success!`,
    });
  } catch (error) {
    yield put({
      type: actions.EDIT_USER_FAIL,
      payload: { error },
    });
    notification.error({
      message: `${isUpdateUser ? "Update" : "Add"} user failed!`,
    });
  }
}

function _updateUser(body) {
  return userApi.updateUser(body).then((response) => {
    return response;
  });
}

function _addNewUser(body) {
  return userApi.addNewUser(body).then((response) => {
    return response;
  });
}

export default function* watchUsersAsync() {
  yield takeLatest(actions.GET_ALL_USER, getAllUser);
  yield takeLatest(actions.GET_USERS_BY_FILTERS, getUsersByFilters);
  yield takeLatest(actions.GET_USER_INFO_BY_ID, getUserInfoById);
  yield takeLatest(actions.UPDATE_PASSWORD, updatePassword);
  yield takeLatest(actions.EDIT_USER, editUser);
}
