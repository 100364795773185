import axios from "axios";
import { urlQueryFromObject } from "../helpers/UtilsHelper";
const checkInPath = "/check-in";
const checkOutPath = "/check-out";
const timeKeepingsPath = "/time-keepings";
const currentStatusTodayPath = "/working-time";
const getTotalWorkingTimePath = "/time-in-month";
const getCheckingStatusPath = "/checking-status";
const getReportByAdminPath = "/admin-report";
const getReportByMemberPath = "/member-report";
const exportCSVReportByAdminPath = "/admin-report/report-csv";
const exportCSVReportByMemberPath = "/member-report/report-csv";
const checkInOutUsersThatDayPath = "/active-users";
const monthlyOverviewPath = "/monthly-overview";
const calendarsPath = "/calendars";
export default {
  async checkIn() {
    let response = await axios.post(checkInPath);
    return response.data;
  },
  async checkOut(data) {
    let response = await axios.post(checkOutPath, data);
    return response.data;
  },
  async getUserTimeKeepings(filters) {
    const path = `${timeKeepingsPath}${urlQueryFromObject(filters)}`;
    let response = await axios.get(path);
    return response.data;
  },
  async addUserTimeKeepings(data) {
    let response = await axios.post(timeKeepingsPath, data);
    return response.data;
  },
  async updateUserTimeKeepings(id, data) {
    const path = `${timeKeepingsPath}/${id}`
    let response = await axios.put(path, data);
    return response.data;
  },
  async deleteUserTimeKeepings(id) {
    const path = `${timeKeepingsPath}/${id}`;
    let response = await axios.delete(path);
    return response.data;
  },
  async getCurrentStatusToday() {
    let response = await axios.get(currentStatusTodayPath);
    return response.data;
  },
  async getTotalWorkingTime() {
    let response = await axios.get(getTotalWorkingTimePath);
    return response.data;
  },
  async getCheckingStatus() {
    let response = await axios.get(getCheckingStatusPath);
    return response.data;
  },
  async getReportByAdmin(filters) {
    const path = `${getReportByAdminPath}${urlQueryFromObject(filters)}`;
    let response = await axios.get(path);
    return response.data;
  },
  async getReportByMember(filters) {
    const path = `${getReportByMemberPath}${urlQueryFromObject(filters)}`;
    let response = await axios.get(path);
    return response.data;
  },
  async exportCSVReportByAdmin(filters) {
    const path = `${exportCSVReportByAdminPath}${urlQueryFromObject(filters)}`;
    let response = await axios.get(path, {
      responseType: "blob",
      timeout: 30000,
    });
    return response.data;
  },
  async exportCSVReportByMember(filters) {
    const path = `${exportCSVReportByMemberPath}${urlQueryFromObject(filters)}`;
    let response = await axios.get(path, {
      responseType: "blob",
      timeout: 30000,
    });
    return response.data;
  },
  async getCheckInOutUsersThatDay() {
    let response = await axios.get(
      // Get all
      `${checkInOutUsersThatDayPath}${urlQueryFromObject({})}`,
    );
    return response.data;
  },
  async getMonthlyOverview() {
    let response = await axios.get(monthlyOverviewPath);
    return response.data;
  },
  async getCalendars() {
    let response = await axios.get(calendarsPath);
    return response.data;
  },
};
