import axios from "axios";
const path = `/global-messages`;

export default {
  async getGlobalMessages() {
    let response = await axios.get(path);
    return response.data;
  },
  async updateGlobalMessages(messages) {
    let response = await axios.put(`${path}/${messages.id}`, messages);
    return response.data;
  },
  async addGlobalMessage(messages) {
    let response = await axios.post(path, messages);
    return response.data;
  },
};
