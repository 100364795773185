const actions = {
  GET_USER_INFO_BY_ID: "common/GET_USER_INFO_BY_ID",
  GET_USER_INFO_BY_ID_SUCCESS: "common/GET_USER_INFO_BY_ID_SUCCESS",
  GET_USER_INFO_BY_ID_FAIL: "common/GET_USER_INFO_BY_ID_FAIL",

  GET_GLOBAL_MESSAGES: "common/GET_GLOBAL_MESSAGES",
  GET_GLOBAL_MESSAGES_SUCCESS: "common/GET_GLOBAL_MESSAGES_SUCCESS",
  GET_GLOBAL_MESSAGES_FAIL: "common/GET_GLOBAL_MESSAGES_FAIL",

  UPDATE_GLOBAL_MESSAGES: "common/UPDATE_GLOBAL_MESSAGES",
  UPDATE_GLOBAL_MESSAGES_SUCCESS: "common/UPDATE_GLOBAL_MESSAGES_SUCCESS",
  UPDATE_GLOBAL_MESSAGES_FAIL: "common/UPDATE_GLOBAL_MESSAGES_FAIL",
};

export default actions;

export const getUserInfoById = (userId) => ({
  type: actions.GET_USER_INFO_BY_ID,
  payload: { userId },
});

export const getGlobalMessages = () => ({
  type: actions.GET_GLOBAL_MESSAGES,
});

export const updateGlobalMessages = (messages) => ({
  type: actions.UPDATE_GLOBAL_MESSAGES,
  payload: { messages },
});
