const actions = {
  GET_REQUEST_HOLIDAYS: "requestHoliday/GET_REQUEST_HOLIDAYS",
  GET_REQUEST_HOLIDAYS_SUCCESS: "requestHoliday/GET_REQUEST_HOLIDAYS_SUCCESS",
  GET_REQUEST_HOLIDAYS_FAIL: "requestHoliday/GET_REQUEST_HOLIDAYS_FAIL",

  GET_MY_REQUEST_HOLIDAYS: "requestHoliday/GET_MY_REQUEST_HOLIDAYS",
  GET_MY_REQUEST_HOLIDAYS_SUCCESS: "requestHoliday/GET_MY_REQUEST_HOLIDAYS_SUCCESS",
  GET_MY_REQUEST_HOLIDAYS_FAIL: "requestHoliday/GET_MY_REQUEST_HOLIDAYS_FAIL",
};

export default actions;

export const getRequestHolidays = (filters) => ({
  type: actions.GET_REQUEST_HOLIDAYS,
  payload: { filters },
});

export const getMyRequestHolidays = (filters) => ({
  type: actions.GET_MY_REQUEST_HOLIDAYS,
  payload: { filters },
});