import actions from "./actions";
import cons from "../../constants";
const initState = {
  loading: false,
  quotes: [],
  // Pagination
  current: cons.PAGINATION_DEFAULT_VALUE.CURRENT,
  pageSize: cons.PAGINATION_DEFAULT_VALUE.PAGE_SIZE,
  total: 0,
}

export default function quoteReducer(state = initState, action) {
  let { type, payload } = action;
  switch (type) {
    case actions.GET_QUOTES:
      return {
        ...state,
        loading: true
      };
    case actions.GET_QUOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        quotes: payload.result,
        current: payload.offset / payload.limit + 1,
        pageSize: payload.limit,
        total: payload.count,
      };
    case actions.GET_QUOTES_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
