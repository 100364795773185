import actions from "./actions";
import cons from "../../constants";
const initState = {
  loading: false,
  projects: [],
  // Pagination
  current: cons.PAGINATION_DEFAULT_VALUE.CURRENT,
  pageSize: cons.PAGINATION_DEFAULT_VALUE.PAGE_SIZE,
  total: 0,

  report: {
    loading: false,
    data: []
  }
}

export default function projectReducer(state = initState, action) {
  let { type, payload } = action;
  switch (type) {
    case actions.GET_PROJECTS:
      return {
        ...state,
        loading: true
      };
    case actions.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: payload.result,
        current: payload.offset / payload.limit + 1,
        pageSize: payload.limit,
        total: payload.count,
      };
    case actions.GET_PROJECTS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actions.GET_USER_PROJECT_REPORT:
    case actions.GET_ADMIN_PROJECT_REPORT:
      return {
        ...state,
        report: {
          ...state.report,
          loading: true
        }
      };
    case actions.GET_PROJECT_REPORT_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          loading: false,
          data: payload
        }
      };
    case actions.GET_PROJECT_REPORT_FAIL:
      return {
        ...state,
        report: {
          ...state.report,
          loading: false
        }
      };
    default:
      return state;
  }
}
