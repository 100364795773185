import axios from "axios";
import { urlQueryFromObject } from "../helpers/UtilsHelper";
const usersPath = "/users";

export default {
  async getAllUsers(filters) {
    const path = `${usersPath}${urlQueryFromObject(filters)}`;
    let response = await axios.get(path);
    return response.data;
  },
  async getUserInfoById(userId) {
    const path = `${usersPath}/${userId}`;
    let response = await axios.get(path);
    return response.data;
  },
  async updateFistLoginByUserId(userId) {
    const path = `${usersPath}/${userId}/update-first-login`;
    let response = await axios.put(path);
    return response.data;
  },
  async updateUser(body) {
    const path = `${usersPath}/${body.id}`;
    let response = await axios.patch(path, body);
    return response.data;
  },
  async addNewUser(body) {
    const path = `${usersPath}`;
    let response = await axios.post(path, body);
    return response.data;
  },
};
