import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";

import { requestResetPassword } from "../../redux/resetPassword/actions";

import { Form, Input, Button, Card, Row, Col } from "antd";
import Loading from "../../components/Loading";
import ErrorSummary from "../../components/ErrorSummary";
import { MailOutlined, ArrowLeftOutlined } from "@ant-design/icons";

function RequestResetPassword(props) {
  const dispatch = useDispatch();

  const errors = useSelector((state) => state.resetPassword.errors);
  const loading = useSelector((state) => state.resetPassword.loading);
  const errorFromServer = errors && errors.response ? errors.response.data : {};

  const [email, setEmail] = useState("");
  const onFinish = (values) => {
    dispatch(requestResetPassword(values.email));
  };
  return (
    <div style={{ backgroundColor: "#cfeeff", height: "100%" }}>
      {loading ? <Loading /> : ""}
      <Row justify="center" align="middle" style={{ height: "100%" }}>
        <Col lg={6} md={12} sm={20}>
          <Card style={{ width: "100%" }}>
            <Form
              layout="vertical"
              name="reset-password"
              className="reset-password-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Row justify="center" align="middle" gutter={[16, 16]}>
                <Col>
                  <img
                    alt="logo"
                    src={require("../../assets/images/logo.png")}
                    style={{ width: 100, textAlign: "center" }}
                  />
                </Col>
              </Row>
              <Form.Item
                label="Please enter your email to reset password!"
                name="email"
                rules={[
                  { required: true, message: "Please input your Email!" },
                ]}
              >
                <Input
                  value={email}
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  maxLength={255}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <ErrorSummary error={errorFromServer} />
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", marginBottom: 15 }}
                  disabled={email ? false : true}
                >
                  Submit
                </Button>
                <Link to="/login">
                  <ArrowLeftOutlined /> Login page!
                </Link>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default RequestResetPassword;
