import { call, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { notification } from "antd";
import projectApi from "api/projectApi";

function* getProjects({ payload }) {
  try {
    const response = yield call(_getProjects.bind(this, payload.filters));
    yield put({
      type: actions.GET_PROJECTS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: actions.GET_PROJECTS_FAIL,
      payload: { error },
    });
    notification.error({ message: "Get projects failed!" });
  }
}

function _getProjects(filters) {
  return projectApi.getProjects(filters).then((response) => {
    return response;
  });
}

function* getUserProjectReport({ payload }) {
  try {
    const response = yield call(_getUserProjectReport.bind(this, payload.filters));
    yield put({
      type: actions.GET_PROJECT_REPORT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: actions.GET_PROJECT_REPORT_FAIL,
      payload: { error },
    });
    notification.error({ message: "Get project report failed!" });
  }
}

function _getUserProjectReport(filters) {
  return projectApi.getUserProjectReport(filters).then((response) => {
    return response;
  });
}

function* getAdminProjectReport({ payload }) {
  try {
    const response = yield call(_getAdminProjectReport.bind(this, payload.filters));
    yield put({
      type: actions.GET_PROJECT_REPORT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: actions.GET_PROJECT_REPORT_FAIL,
      payload: { error },
    });
    notification.error({ message: "Get project report failed!" });
  }
}

function _getAdminProjectReport(filters) {
  return projectApi.getAdminProjectReport(filters).then((response) => {
    return response;
  });
}

export default function* watchProjectAsync() {
  yield takeLatest(actions.GET_PROJECTS, getProjects);
  yield takeLatest(actions.GET_USER_PROJECT_REPORT, getUserProjectReport);
  yield takeLatest(actions.GET_ADMIN_PROJECT_REPORT, getAdminProjectReport);
}
