import actions from "./actions";
import cons from "../../constants";
let initState = () => ({
  loading: false,
  loadingOfGetAllUser: false,
  userInfoData: {},
  allUser: {},
  errors: {},

  // Pagination
  pgCurrent: cons.PAGINATION_DEFAULT_VALUE.CURRENT,
  pgPageSize: cons.PAGINATION_DEFAULT_VALUE.PAGE_SIZE,
  pgTotal: 0,
});

export default function usersReducer(state = initState(), action) {
  let { type, payload } = action;
  switch (type) {
    // Get data user
    case actions.GET_USER_INFO_BY_ID:
      return { ...state, loading: true };
    case actions.GET_USER_INFO_BY_ID_SUCCESS:
      return { ...state, loading: false, userInfoData: payload.userInfoData };
    case actions.GET_USER_INFO_BY_ID_FAIL:
      return { ...state, loading: false, errors: payload.error };

    // Update first login
    case actions.UPDATE_USER_WHEN_FIRST_LOGIN:
      return { ...state, loading: true };
    case actions.UPDATE_USER_WHEN_FIRST_LOGIN_SUCCESS:
      return { ...state, loading: false, errors: {} };
    case actions.UPDATE_USER_WHEN_FIRST_LOGIN_FAIL:
      return { ...state, loading: false, errors: payload.error };

    // Update password
    case actions.UPDATE_PASSWORD:
      return { ...state, loading: true };
    case actions.UPDATE_PASSWORD_SUCCESS:
      return { ...state, loading: false, errors: {} };
    case actions.UPDATE_PASSWORD_FAIL:
      return { ...state, loading: false, errors: payload.error };

    // Get all user by filters
    case actions.GET_USERS_BY_FILTERS:
      return { ...state, loadingOfGetAllUser: true };
    case actions.GET_USERS_BY_FILTERS_SUCCESS:
      return {
        ...state,
        loadingOfGetAllUser: false,
        errors: {},
        allUser: payload.allUser,
        pgCurrent: payload.allUser.offset / payload.allUser.limit + 1,
        pgPageSize: payload.allUser.limit,
        pgTotal: payload.allUser.count,
      };
    case actions.GET_USERS_BY_FILTERS_FAIL:
      return { ...state, loadingOfGetAllUser: false, errors: payload.error };

    // Get all user
    case actions.GET_ALL_USER:
      return { ...state, loadingOfGetAllUser: true };
    case actions.GET_ALL_USER_SUCCESS:
      return {
        ...state,
        loadingOfGetAllUser: false,
        errors: {},
        allUser: payload.allUser,
      };
    case actions.GET_ALL_USER_FAIL:
      return { ...state, loadingOfGetAllUser: false, errors: payload.error };

    // Add/Update user
    case actions.EDIT_USER:
      return { ...state, loading: true };
    case actions.EDIT_USER_SUCCESS:
      return { ...state, loading: false, errors: {} };
    case actions.EDIT_USER_FAIL:
      return { ...state, loading: false, errors: payload.error };
    default:
      return state;
  }
}
