const actions = {
  GET_CLIENTS: "requestHoliday/GET_CLIENTS",
  GET_CLIENTS_SUCCESS: "requestHoliday/GET_CLIENTS_SUCCESS",
  GET_CLIENTS_FAIL: "requestHoliday/GET_CLIENTS_FAIL",
};

export default actions;

export const getClients = (filters) => ({
  type: actions.GET_CLIENTS,
  payload: { filters },
});