import axios from "axios";
import { urlQueryFromObject } from "../helpers/UtilsHelper";
const requestHolidayPath = "/request-holidays";

export default {
    async getRequestHolidays(filters) {
        const path = `${requestHolidayPath}${urlQueryFromObject(filters)}`;
        let response = await axios.get(path);
        return response.data;
    },
    async getMyRequestHolidays(filters) {
        const path = `${requestHolidayPath}/me${urlQueryFromObject(filters)}`;
        let response = await axios.get(path);
        return response.data;
    },
    async exportCSVReport(filters) {
        const path = `${requestHolidayPath}/export-csv${urlQueryFromObject(filters)}`;
        let response = await axios.get(path, {
            responseType: "blob",
            timeout: 30000,
        });
        return response.data;
    },
    async createRequestHoliday(data) {
        let response = await axios.post(requestHolidayPath, data);
        return response.data;
    },
    async getRequestHolidayById(id) {
        const path = `${requestHolidayPath}/${id}`;
        let response = await axios.get(path);
        return response.data;
    },
    async submitRequestHolidayById(id) {
        const path = `${requestHolidayPath}/${id}/submit`;
        let response = await axios.patch(path, {});
        return response.data;
    },
    async updateRequestHolidayById(id, data) {
        const path = `${requestHolidayPath}/${id}`;
        let response = await axios.put(path, data);
        return response.data;
    },
    async processRequest(id, data) {
        const path = `${requestHolidayPath}/${id}/approve`;
        let response = await axios.put(path, data);
        return response.data;
    },
    async deleteRequestHolidayById(id) {
        const path = `${requestHolidayPath}/${id}`;
        let response = await axios.delete(path);
        return response.data;
    },
};
