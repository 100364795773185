const actions = {
  GET_PROJECTS: "project/GET_PROJECTS",
  GET_PROJECTS_SUCCESS: "project/GET_PROJECTS_SUCCESS",
  GET_PROJECTS_FAIL: "project/GET_PROJECTS_FAIL",

  GET_USER_PROJECT_REPORT: "project/GET_USER_PROJECT_REPORT",
  GET_ADMIN_PROJECT_REPORT: "project/GET_ADMIN_PROJECT_REPORT",
  GET_PROJECT_REPORT_SUCCESS: "project/GET_PROJECT_REPORT_SUCCESS",
  GET_PROJECT_REPORT_FAIL: "project/GET_PROJECT_REPORT_FAIL",
};

export default actions;

export const getProjects = (filters) => ({
  type: actions.GET_PROJECTS,
  payload: { filters },
});

export const getUserProjectReport = (filters) => ({
  type: actions.GET_USER_PROJECT_REPORT,
  payload: { filters },
});

export const getAdminProjectReport = (filters) => ({
  type: actions.GET_ADMIN_PROJECT_REPORT,
  payload: { filters },
});