import axios from "axios";
import { urlQueryFromObject } from "../helpers/UtilsHelper";
const companyHolidayPath = "/company-holidays";

export default {
  async getCompanyHolidays(filters) {
    const path = `${companyHolidayPath}${urlQueryFromObject(filters)}`;
    let response = await axios.get(path);
    return response.data;
  },
  async createCompanyHoliday(data) {
    let response = await axios.post(companyHolidayPath, data);
    return response.data;
  },
  async getCompanyHolidayById(id) {
    const path = `${companyHolidayPath}/${id}`;
    let response = await axios.get(path);
    return response.data;
  },
  async updateCompanyHolidayById(id, data) {
    const path = `${companyHolidayPath}/${id}`;
    let response = await axios.put(path, data);
    return response.data;
  },
  async deleteCompanyHolidayById(id) {
    const path = `${companyHolidayPath}/${id}`;
    let response = await axios.delete(path);
    return response.data;
  },
};
