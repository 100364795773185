import actions from "./actions";
let initState = () => ({
  loading: false,
  userInfoData: {},
  errors: {},
  globalMessages: null,
  globalMessagesLoading: false,
});

export default function commonReducer(state = initState(), action) {
  let { type, payload } = action;
  switch (type) {
    // Get data user
    case actions.GET_USER_INFO_BY_ID:
      return { ...state, loading: true };
    case actions.GET_USER_INFO_BY_ID_SUCCESS:
      return { ...state, loading: false, userInfoData: payload.userInfoData };
    case actions.GET_USER_INFO_BY_ID_FAIL:
      return { ...state, loading: false, errors: payload.error };

    // Get global messages has been set by admin
    case actions.GET_GLOBAL_MESSAGES:
      return { ...state, globalMessagesLoading: true };
    case actions.GET_GLOBAL_MESSAGES_SUCCESS:
      return {
        ...state,
        globalMessagesLoading: false,
        globalMessages: payload.globalMessages,
        errors: {},
      };
    case actions.GET_GLOBAL_MESSAGES_FAIL:
      return {
        ...state,
        globalMessagesLoading: false,
        errors: payload.error,
        globalMessages: payload.globalMessages,
      };

    // Update global messages by admin
    case actions.UPDATE_GLOBAL_MESSAGES:
      return { ...state, globalMessagesLoading: true };
    case actions.UPDATE_GLOBAL_MESSAGES_SUCCESS:
      return {
        ...state,
        globalMessagesLoading: false,
        errors: {},
      };
    case actions.UPDATE_GLOBAL_MESSAGES_FAIL:
      return { ...state, globalMessagesLoading: false, errors: payload.error };
    default:
      return state;
  }
}
