const ERRORS_MESSAGE = {
  exampleFieldErrors: {
    field: "example-field",
    nameDisplay: "example name",
    message: "example message",
  },
};

export default {
  ERRORS_MESSAGE,
};
