const actions = {
  GET_QUOTES: "requestHoliday/GET_QUOTES",
  GET_QUOTES_SUCCESS: "requestHoliday/GET_QUOTES_SUCCESS",
  GET_QUOTES_FAIL: "requestHoliday/GET_QUOTES_FAIL",
};

export default actions;

export const getQuotes = (filters) => ({
  type: actions.GET_QUOTES,
  payload: { filters },
});