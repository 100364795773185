import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkIn, checkOut, getCheckedInStatus } from "../../redux/workingTime/actions";
import { Button } from "antd";
import { useState } from "react";
import WorkDetailsModal from '../WorkDetailsModal';
import { getProjects } from "redux/projects/actions";

const CheckInOut = () => {
  const dispatch = useDispatch();
  const { loading, isCheckedIn } = useSelector((state) => state.workingTime);
  const [isShowDetailsModal, setShowDetailsModal] = useState(false);

  useEffect(() => {
    dispatch(getCheckedInStatus());
    dispatch(getProjects());
  }, [dispatch]);

  const handleCheckIn = () => {
    dispatch(checkIn());
  };

  const checkedStyle = {
    backgroundColor: "#599859",
    borderColor: "#008e00",
  };

  return (
    <>
      <Button
        type="primary"
        loading={loading}
        onClick={() => isCheckedIn ? setShowDetailsModal(true) : handleCheckIn()}
        style={isCheckedIn ? checkedStyle : {}}
      >
        {isCheckedIn ? "Check Out" : "Check In"}
      </Button>
      <WorkDetailsModal
        isVisible={isShowDetailsModal}
        onClose={() => setShowDetailsModal(false)}
        onSubmit={(data) => dispatch(checkOut(data))}
      />
    </>
  );
};

export default CheckInOut;
