import axios from "axios";
import { arrayToParams, urlQueryFromObject } from "../helpers/UtilsHelper";

const projectPath = `/projects`;
const userProjectReportPath = `/user-project-report`;
const adminProjectReportPath = `/admin-project-report`;

export default {
  async getProjects(filters) {
    const path = `${projectPath}${urlQueryFromObject(filters)}`;
    const response = await axios.get(path);
    return response.data;
  },
  async createProject(data) {
    const response = await axios.post(projectPath, data);
    return response.data;
  },
  // async updateProject(id, data) {
  //   const path = `${projectPath}/${id}`;
  //   const response = await axios.put(path, data);
  //   return response.data;
  // },
  async updateProjectStatus(id, isActive) {
    const path = `${projectPath}/${id}`;
    const response = await axios.patch(path, { isActive });
    return response.data;
  },
  // async deleteProject(id) {
  //   const path = `${projectPath}/${id}`;
  //   const response = await axios.delete(path);
  //   return response.data;
  // },
  async deleteProjects(ids) {
    const path = `${projectPath}/delete-ids?${arrayToParams(ids)}`;
    const response = await axios.delete(path);
    return response.data;
  },

  async getUserProjectReport(filters) {
    const path = `${userProjectReportPath}${urlQueryFromObject(filters)}`;
    const response = await axios.get(path);
    return response.data;
  },
  async exportUserProjectReport(filters) {
    const path = `${userProjectReportPath}/export-csv${urlQueryFromObject(filters)}`;
    let response = await axios.get(path, {
      responseType: "blob",
      timeout: 30000,
    });
    return response.data;
  },
  async getAdminProjectReport(filters) {
    const path = `${adminProjectReportPath}${urlQueryFromObject(filters)}`;
    const response = await axios.get(path);
    return response.data;
  },
  async exportAdminProjectReport(filters) {
    const path = `${adminProjectReportPath}/export-csv${urlQueryFromObject(filters)}`;
    let response = await axios.get(path, {
      responseType: "blob",
      timeout: 30000,
    });
    return response.data;
  },
};
