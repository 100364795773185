import actions from "./actions";
let initState = () => ({
  loading: false,
  errors: {},
  currentStatus: {},
  currentStatusLoading: false,

  monthlyOverview: {
    monthlyOverviewLoading: false,
    totalAvailableHours: 0,
    prescribedHours: 0,
    workedHours: 0,
    workedOvertimeHours: 0,
    availableOvertimeHours: 0,
    remainingDays: 0,
    remainingHours: 0,
  },

  calendar: {
    loading: false,
    months: []
  },

  totalWorkingTime: 0,
  totalWorkingTimeLoading: false,
  isCheckedIn: false,
  reportLoading: false,
  reportData: {},
  checkInOutUsersThatDayData: {},
  checkInOutUThatDayLoading: false,
  lastCheckIn: null,
});

export default function workingTimeReducer(state = initState(), action) {
  let { type, payload } = action;
  switch (type) {
    // Check in
    case actions.CHECK_IN:
      return { ...state, loading: true, errors: {} };
    case actions.CHECK_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        isCheckedIn: true,
        lastCheckIn: new Date()
      };
    case actions.CHECK_IN_FAIL:
      return {
        ...state,
        loading: false,
        errors: payload.error
      };
    // Check out
    case actions.CHECK_OUT:
      return { ...state, loading: true, errors: {} };
    case actions.CHECK_OUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isCheckedIn: false
      };
    case actions.CHECK_OUT_FAIL:
      return {
        ...state,
        loading: false,
        errors: payload.error
      };

    // Get current status
    case actions.GET_CURRENT_STATUS:
      return { ...state, currentStatusLoading: true };
    case actions.GET_CURRENT_STATUS_SUCCESS:
      return {
        ...state,
        currentStatusLoading: false,
        currentStatus: payload.currentStatus,
        errors: {},
      };
    case actions.GET_CURRENT_STATUS_FAIL:
      return {
        ...state,
        currentStatusLoading: false,
        errors: payload.error,
      };

    // Get monthly overview
    case actions.GET_MONTHLY_OVERVIEW:
      return {
        ...state,
        monthlyOverview: {
          ...state.monthlyOverview,
          monthlyOverviewLoading: true
        }
      };
    case actions.GET_MONTHLY_OVERVIEW_SUCCESS:
      return {
        ...state,
        monthlyOverview: {
          ...payload,
          monthlyOverviewLoading: false
        },
        errors: {},
      };
    case actions.GET_MONTHLY_OVERVIEW_FAIL:
      return {
        ...state,
        monthlyOverview: {
          ...state.monthlyOverview,
          monthlyOverviewLoading: false,
        },
        errors: payload.error,
      };


    // Get current status
    case actions.GET_TOTAL_WORKING_TIME:
      return { ...state, totalWorkingTimeLoading: true };
    case actions.GET_TOTAL_WORKING_TIME_SUCCESS:
      return {
        ...state,
        totalWorkingTimeLoading: false,
        totalWorkingTime: payload.totalWorkingTime,
        errors: {},
      };
    case actions.GET_TOTAL_WORKING_TIME_FAIL:
      return {
        ...state,
        totalWorkingTimeLoading: false,
        errors: payload.error,
      };

    // Get checked in status
    case actions.GET_CHECKED_IN_STATUS:
      return { ...state, loading: true };
    case actions.GET_CHECKED_IN_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isCheckedIn: payload.isCheckedIn,
        lastCheckIn: payload.lastCheckIn,
        errors: {},
      };
    case actions.GET_CHECKED_IN_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        errors: payload.error,
      };

    // Get report by user id and report type
    case actions.GET_REPORT:
      return { ...state, reportLoading: true };
    case actions.GET_REPORT_SUCCESS:
      return {
        ...state,
        reportLoading: false,
        reportData: payload.reportData,
        errors: {},
      };
    case actions.GET_REPORT_FAIL:
      return {
        ...state,
        reportLoading: false,
        errors: payload.error,
      };

    // Get report by user id and report type
    case actions.GET_CHECK_IN_OUT_USERS_THAT_DAY:
      return { ...state, checkInOutUThatDayLoading: true };
    case actions.GET_CHECK_IN_OUT_USERS_THAT_DAY_SUCCESS:
      return {
        ...state,
        checkInOutUThatDayLoading: false,
        checkInOutUsersThatDayData: payload.checkInOutUsersThatDayData,
        errors: {},
      };
    case actions.GET_CHECK_IN_OUT_USERS_THAT_DAY_FAIL:
      return {
        ...state,
        checkInOutUThatDayLoading: false,
        errors: payload.error,
      };


    // Get calendars
    case actions.GET_CALENDARS:
      return {
        ...state, calendar: {
          ...state.calendar,
          loading: true
        }
      };
    case actions.GET_CALENDARS_SUCCESS:
      return {
        ...state, calendar: {
          months: payload,
          loading: false
        }
      };
    case actions.GET_CALENDARS_FAIL:
      return {
        ...state, calendar: {
          ...state.calendar,
          loading: false
        }
      };


    default:
      return state;
  }
}
