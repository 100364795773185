const actions = {
  CHECK_IN: "workingTime/CHECK_IN",
  CHECK_IN_SUCCESS: "workingTime/CHECK_IN_SUCCESS",
  CHECK_IN_FAIL: "workingTime/CHECK_IN_FAIL",

  CHECK_OUT: "workingTime/CHECK_OUT",
  CHECK_OUT_SUCCESS: "workingTime/CHECK_OUT_SUCCESS",
  CHECK_OUT_FAIL: "workingTime/CHECK_OUT_FAIL",

  GET_CURRENT_STATUS: "workingTime/GET_CURRENT_STATUS",
  GET_CURRENT_STATUS_SUCCESS: "workingTime/GET_CURRENT_STATUS_SUCCESS",
  GET_CURRENT_STATUS_FAIL: "workingTime/GET_CURRENT_STATUS_FAIL",

  GET_MONTHLY_OVERVIEW: "workingTime/GET_MONTHLY_OVERVIEW",
  GET_MONTHLY_OVERVIEW_SUCCESS: "workingTime/GET_MONTHLY_OVERVIEW_SUCCESS",
  GET_MONTHLY_OVERVIEW_FAIL: "workingTime/GET_MONTHLY_OVERVIEW_FAIL",

  GET_TOTAL_WORKING_TIME: "workingTime/GET_TOTAL_WORKING_TIME",
  GET_TOTAL_WORKING_TIME_SUCCESS: "workingTime/GET_TOTAL_WORKING_TIME_SUCCESS",
  GET_TOTAL_WORKING_TIME_FAIL: "workingTime/GET_TOTAL_WORKING_TIME_FAIL",

  GET_CHECKED_IN_STATUS: "workingTime/GET_CHECKED_IN_STATUS",
  GET_CHECKED_IN_STATUS_SUCCESS: "workingTime/GET_CHECKED_IN_STATUS_SUCCESS",
  GET_CHECKED_IN_STATUS_FAIL: "workingTime/GET_CHECKED_IN_STATUS_FAIL",

  GET_REPORT: "workingTime/GET_REPORT",
  GET_REPORT_SUCCESS: "workingTime/GET_REPORT_SUCCESS",
  GET_REPORT_FAIL: "workingTime/GET_REPORT_FAIL",

  GET_CHECK_IN_OUT_USERS_THAT_DAY: "workingTime/GET_CHECK_IN_OUT_USERS_THAT_DAY",
  GET_CHECK_IN_OUT_USERS_THAT_DAY_SUCCESS: "workingTime/GET_CHECK_IN_OUT_USERS_THAT_DAY_SUCCESS",
  GET_CHECK_IN_OUT_USERS_THAT_DAY_FAIL: "workingTime/GET_CHECK_IN_OUT_USERS_THAT_DAY_FAIL",

  GET_CALENDARS: "calendar/GET_CALENDARS",
  GET_CALENDARS_SUCCESS: "calendar/GET_CALENDARS_SUCCESS",
  GET_CALENDARS_FAIL: "calendar/GET_CALENDARS_FAIL",
};

export default actions;

export const checkIn = () => ({ type: actions.CHECK_IN });
export const checkOut = (payload) => ({ type: actions.CHECK_OUT, payload });

export const getCurrentStatus = () => ({ type: actions.GET_CURRENT_STATUS });

export const getMonthlyOverview = () => ({ type: actions.GET_MONTHLY_OVERVIEW });

export const getTotalWorkingTime = () => ({ type: actions.GET_TOTAL_WORKING_TIME });

export const getCheckedInStatus = () => ({ type: actions.GET_CHECKED_IN_STATUS });

export const getReport = (role, filters) => ({ type: actions.GET_REPORT, payload: { role, filters } });

export const getCheckInOutUsersThatDay = () => ({ type: actions.GET_CHECK_IN_OUT_USERS_THAT_DAY });

export const getCalendars = () => ({ type: actions.GET_CALENDARS });