import React from "react";
import * as Icons from "@ant-design/icons";

// ============= Shared pages ====================
const Login = React.lazy(() => import("./pages/shared/Login"));

// ============= Admin pages ====================
const AdminSummary = React.lazy(() => import("./pages/admin/Summary"));
const AdminMembers = React.lazy(() => import("./pages/admin/Members"));
const AdminEditUser = React.lazy(() => import("./pages/admin/EditUser"));

const AdminSettingCompanyHolidays = React.lazy(() => import("./pages/admin/Settings/CompanyHolidays"));
const AdminSettingWorkTimeRule = React.lazy(() => import("./pages/admin/Settings/WorkTimeRule"));
const AdminSettingPaidHolidayRule = React.lazy(() => import("./pages/admin/Settings/PaidHolidayRule"));

const ProjectSettingClients = React.lazy(() => import("./pages/admin/ProjectSettings/Clients"));
const ProjectSettingProjects = React.lazy(() => import("./pages/admin/ProjectSettings/Projects"));
const ProjectSettingQuotes = React.lazy(() => import("./pages/admin/ProjectSettings/Quotes"));
const AdminProjectReporting = React.lazy(() => import("./pages/admin/ProjectSettings/Reporting"));
// const UserProjectReporting = React.lazy(() => import("./pages/member/Reporting"));

// ============= Members pages ====================


// ============= Shared pages ====================
const Overview = React.lazy(() => import("./pages/shared/OverView"));
const MyProfile = React.lazy(() => import("./pages/shared/MyProfile"));
const Reporting = React.lazy(() => import("./pages/shared/Reporting"));
const Calendars = React.lazy(() => import("./pages/shared/Calendars"));

export const adminRoutes = [
  {
    path: "/admin/overview",
    name: "Overview",
    icon: Icons.DashboardOutlined,
    component: Overview,
  },
  {
    path: "/admin/summary",
    name: "Summary",
    icon: Icons.OrderedListOutlined,
    component: AdminSummary,
  },
  {
    path: "/admin/my-profile",
    component: MyProfile,
    icon: Icons.DashboardOutlined,
  },
  {
    path: "/admin/members",
    name: "Members",
    component: AdminMembers,
    icon: Icons.TeamOutlined,
  },
  {
    path: "/admin/edit-user",
    component: AdminEditUser,
  },
  {
    path: "/admin/reporting",
    name: "Reporting",
    component: Reporting,
    icon: Icons.ContainerOutlined,
  },
  {
    icon: Icons.SettingOutlined,
    name: "Time Settings",
    children: [
      {
        path: "/admin/settings/company-holidays",
        name: "Company Holidays",
        component: AdminSettingCompanyHolidays,
      },
      {
        path: "/admin/settings/work-time-rule",
        name: "Work Time Rule",
        component: AdminSettingWorkTimeRule,
      },
      {
        path: "/admin/settings/paid-holiday-rule",
        name: "Paid Holiday Rule",
        component: AdminSettingPaidHolidayRule,
      }
    ],
  },
  {
    icon: Icons.BookOutlined,
    name: "Project Settings",
    children: [
      {
        path: "/admin/settings/clients",
        name: "Clients",
        component: ProjectSettingClients,
      },
      {
        path: "/admin/settings/projects",
        name: "Projects",
        component: ProjectSettingProjects,
      },
      {
        path: "/admin/settings/quotes",
        name: "Quotes",
        component: ProjectSettingQuotes,
      },
      {
        path: "/admin/settings/reporting",
        name: "Reporting",
        component: AdminProjectReporting,
      }
    ],
  },
  {
    path: "/admin/calendars",
    name: "Calendars",
    component: Calendars,
    icon: Icons.CalendarOutlined,
  },
];

export const employeeRotes = [
  {
    path: "/member/overview",
    name: "Overview",
    icon: Icons.DashboardOutlined,
    component: Overview,
  },
  {
    path: "/member/my-profile",
    component: MyProfile,
    icon: Icons.DashboardOutlined,
  },
  {
    path: "/member/reporting",
    name: "Reporting",
    component: Reporting,
    icon: Icons.ContainerOutlined,
  },
  {
    path: "/member/calendars",
    name: "Calendars",
    component: Calendars,
    icon: Icons.CalendarOutlined,
  },
  // {
  //   path: "/member/project-reporting",
  //   name: "Project Report",
  //   component: UserProjectReporting,
  //   icon: Icons.PieChartOutlined,
  // },
];

export const publicRoutes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-archive-2",
    component: Login,
    layout: "/auth",
  },
];

const routes = [...adminRoutes, ...employeeRotes];

export default routes;
