import actions from "./actions";
import authHelper from "../../helpers/AuthHelper";

let initState = () => ({
  haveError: false,
  token: authHelper.getToken(),
  isAuthenticated: !!authHelper.getToken(),
  loading: false,
  isShowExpireAlert: false,
  isFirstLogin: false,
  errors: {},
});

export default function authReducer(state = initState(), action) {
  let { type, payload } = action;
  switch (type) {
    case actions.LOGIN:
      return {
        ...state,
        loading: true,
      };
    case actions.LOGIN_AFTER_REGISTER:
      return {
        ...state,
        isAuthenticated: false,
        haveError: false,
        loading: true,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        data: payload,
        haveError: false,
        loading: false,
        token: payload.data.token,
        isFirstLogin: payload.data.isFirstLogin,
      };
    case actions.LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        haveError: true,
        loading: false,
        errors: payload.error,
      };
    case actions.LOGOUT:
      return { ...state, isAuthenticated: false };
    case actions.LOGOUT_EXPIRE:
      return { ...state, isAuthenticated: false, isShowExpireAlert: true };
    default:
      return state;
  }
}
