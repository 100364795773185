const API_BASE = process.env.REACT_APP_API_BASE;
const API_END_POINT = `${API_BASE}/api`;
const TOKEN_LIFE_TIME = 1000 * 60 * 60 * 24; // 1 day
const APP_VERSION = `${process.env.REACT_APP_VERSION}`;

let firebaseConfig = {};

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  firebaseConfig = require("./firebaseConfig").firebaseConfig;
}  else if (process.env.REACT_APP_STAGING) {
  firebaseConfig = require("./firebaseConfigStaging").firebaseConfig;
} else {
  firebaseConfig = require("./firebaseConfigProduction").firebaseConfig;
}

export default {
  API_BASE,
  API_END_POINT,
  TOKEN_LIFE_TIME,
  APP_VERSION,
  firebaseConfig,
};
