import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import jwtDecode from "jwt-decode";
import config from "./config";

// Firebase
import firebase from "firebase/app";

import DefaultLayout from "./components/layouts/DefaultLayout";
import Login from "./pages/shared/Login";
import RequestResetPassword from "./pages/shared/RequestResetPassword";
import ResetPassword from "./pages/shared/ResetPassword";

import { notification } from "antd";

import "./App.scss";

// Config notification
notification.config({
  top: 65,
});

firebase.initializeApp(config.firebaseConfig);
function App() {
  // Check auth
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isShowExpireAlert = useSelector(
    (state) => state.auth.isShowExpireAlert,
  );
  const token = useSelector((state) => state.auth.token);

  useEffect(
    () => {
      // Show notification when has expired
      if (isShowExpireAlert) {
        notification.warning({ message: "Your session has expired!" });
      }
    },
    [isShowExpireAlert],
  );

  const getRolePath = () => {
    const tokenParser = token && jwtDecode(token);
    if (tokenParser) {
      const { is_staff, is_superuser } = tokenParser;
      if (is_superuser) return "/admin";
      if (is_staff) return "/member";
    }
    return "/admin";
  };

  return (
    <HashRouter>
      {isAuthenticated ? (
        <Switch>
          <Route
            path={getRolePath()}
            render={(props) => (
              <DefaultLayout {...props} rolePath={getRolePath()} />
            )}
          />
          <Redirect from="/" to={getRolePath()} />
        </Switch>
      ) : (
        <Switch>
          <Route
            path="/request-reset-password"
            render={(props) => <RequestResetPassword {...props} />}
          />
          <Route
            path="/reset-password"
            render={(props) => <ResetPassword {...props} />}
          />
          <Redirect from="/" exact to={getRolePath()} />
          <Redirect from="/admin" to="/login" />
          <Redirect from="/member" to="/login" />
          <Route path="/login" render={(props) => <Login {...props} />} />
        </Switch>
      )}
    </HashRouter>
  );
}

export default App;
