const actions = {
  GET_USER_HOLIDAYS: "requestHoliday/GET_USER_HOLIDAYS",
  GET_USER_HOLIDAYS_SUCCESS: "requestHoliday/GET_USER_HOLIDAYS_SUCCESS",
  GET_USER_HOLIDAYS_FAIL: "requestHoliday/GET_USER_HOLIDAYS_FAIL",
};

export default actions;

export const getUserHolidays = (filters) => ({
  type: actions.GET_USER_HOLIDAYS,
  payload: { filters },
});