import axios from "axios";
import { urlQueryFromObject } from "../helpers/UtilsHelper";
const userHolidayPath = "/user-holidays";

export default {
    async getUserHolidays(filters) {
        const path = `${userHolidayPath}${urlQueryFromObject(filters)}`;
        let response = await axios.get(path);
        return response.data;
    },
    async updateUserHolidayById(id, data) {
        const path = `${userHolidayPath}/${id}`;
        let response = await axios.put(path, data);
        return response.data;
    }
};
