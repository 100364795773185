const actions = {
  LOGIN: "auth/LOGIN",
  LOGIN_SUCCESS: "auth/LOGIN_SUCCESS",
  LOGIN_FAIL: "auth/LOGIN_FAIL",
  LOGIN_AFTER_REGISTER: "auth/LOGIN_AFTER_REGISTER",
  LOGOUT: "auth/LOGOUT",
  LOGOUT_EXPIRE: "auth/LOGOUT_EXPIRE",
};

export default actions;

export const login = (email, password) => ({
  type: actions.LOGIN,
  payload: { email, password },
});
export const logout = () => ({ type: actions.LOGOUT });
export const logoutExpire = () => ({ type: actions.LOGOUT_EXPIRE });
