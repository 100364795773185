import axios from "axios";
import { arrayToParams, urlQueryFromObject } from "../helpers/UtilsHelper";

const quotePath = `/quotes`;

export default {
  async getQuotes(filters) {
    const path = `${quotePath}${urlQueryFromObject(filters)}`;
    const response = await axios.get(path);
    return response.data;
  },
  async createQuote(data) {
    const response = await axios.post(quotePath, data);
    return response.data;
  },
  async updateQuote(id, data) {
    const path = `${quotePath}/${id}`;
    const response = await axios.patch(path, data);
    return response.data;
  },
  async updateQuoteStatus(id, isActive) {
    const path = `${quotePath}/${id}`;
    const response = await axios.patch(path, { isActive });
    return response.data;
  },
  // async deleteQuote(id) {
  //   const path = `${quotePath}/${id}`;
  //   const response = await axios.delete(path);
  //   return response.data;
  // },
  async deleteQuotes(ids) {
    const path = `${quotePath}/delete-ids?${arrayToParams(ids)}`;
    const response = await axios.delete(path);
    return response.data;
  },
};
