const actions = {
  GET_USER_INFO_BY_ID: "users/GET_USER_INFO_BY_ID",
  GET_USER_INFO_BY_ID_SUCCESS: "users/GET_USER_INFO_BY_ID_SUCCESS",
  GET_USER_INFO_BY_ID_FAIL: "users/GET_USER_INFO_BY_ID_FAIL",

  UPDATE_USER_WHEN_FIRST_LOGIN: "users/UPDATE_USER_WHEN_FIRST_LOGIN",
  UPDATE_USER_WHEN_FIRST_LOGIN_SUCCESS:
    "users/UPDATE_USER_WHEN_FIRST_LOGIN_SUCCESS",
  UPDATE_USER_WHEN_FIRST_LOGIN_FAIL: "users/UPDATE_USER_WHEN_FIRST_LOGIN_FAIL",

  UPDATE_PASSWORD: "users/UPDATE_PASSWORD",
  UPDATE_PASSWORD_SUCCESS: "users/UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAIL: "users/UPDATE_PASSWORD_FAIL",

  GET_ALL_USER: "users/GET_ALL_USER",
  GET_ALL_USER_SUCCESS: "users/GET_ALL_USER_SUCCESS",
  GET_ALL_USER_FAIL: "users/GET_ALL_USER_FAIL",

  GET_USERS_BY_FILTERS: "users/GET_USERS_BY_FILTERS",
  GET_USERS_BY_FILTERS_SUCCESS: "users/GET_USERS_BY_FILTERS_SUCCESS",
  GET_USERS_BY_FILTERS_FAIL: "users/GET_USERS_BY_FILTERS_FAIL",

  EDIT_USER: "users/EDIT_USER",
  EDIT_USER_SUCCESS: "users/EDIT_USER_SUCCESS",
  EDIT_USER_FAIL: "users/EDIT_USER_FAIL",
};

export default actions;

export const getAllUser = () => ({
  type: actions.GET_ALL_USER,
});

export const getUsersByFilters = (filters) => ({
  type: actions.GET_USERS_BY_FILTERS,
  payload: { filters },
});

export const getUserInfoById = (userId) => ({
  type: actions.GET_USER_INFO_BY_ID,
  payload: { userId },
});

export const updateUserWhenFistLogin = (data) => ({
  type: actions.UPDATE_USER_WHEN_FIRST_LOGIN,
  payload: { data },
});

export const updatePassword = (email, oldPassword, newPassword, callBack) => ({
  type: actions.UPDATE_PASSWORD,
  payload: { email, oldPassword, newPassword, callBack },
});

export const editUser = (type, body) => ({
  type: actions.EDIT_USER,
  payload: { type, body },
});
