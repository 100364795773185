import { combineReducers } from "redux";

import auth from "./auth/reducer";
import resetPassword from "./resetPassword/reducer";
import users from "./users/reducer";
import common from "./common/reducer";
import workingTime from "./workingTime/reducer";
import companyHoliday from "./companyHoliday/reducer";
import requestHoliday from "./requestHoliday/reducer";
import userHoliday from "./userHoliday/reducer";
import clients from "./clients/reducer";
import projects from "./projects/reducer";
import quotes from "./quotes/reducer";
import authActions from "./auth/actions";

const appReducer = combineReducers({
  auth,
  resetPassword,
  users,
  common,
  workingTime,
  companyHoliday,
  requestHoliday,
  userHoliday,
  clients,
  projects,
  quotes,
});

// Reset all state when logout
const rootReducer = (state, action) => {
  if (action.type === authActions.LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
