import { all, fork } from "redux-saga/effects";
import auth from "./auth/saga";
import resetPassword from "./resetPassword/saga";
import users from "./users/saga";
import common from "./common/saga";
import workingTime from "./workingTime/saga";
import companyHoliday from "./companyHoliday/saga";
import requestHoliday from "./requestHoliday/saga";
import userHoliday from "./userHoliday/saga";
import clients from "./clients/saga";
import projects from "./projects/saga";
import quotes from "./quotes/saga";

export default function* sagas() {
  yield all([
    fork(auth),
    fork(resetPassword),
    fork(users),
    fork(common),
    fork(workingTime),
    fork(companyHoliday),
    fork(requestHoliday),
    fork(userHoliday),
    fork(clients),
    fork(projects),
    fork(quotes),
  ]);
}
