import actions from "./actions";
import cons from "../../constants";
let initState = () => ({
  myRequests: {
    loading: false,
    holidays: [],
    errors: {},
    // Pagination
    current: cons.PAGINATION_DEFAULT_VALUE.CURRENT,
    pageSize: cons.PAGINATION_DEFAULT_VALUE.PAGE_SIZE,
    total: 0,
  },
  adminRequests: {
    loading: false,
    holidays: [],
    errors: {},
    // Pagination
    current: cons.PAGINATION_DEFAULT_VALUE.CURRENT,
    pageSize: cons.PAGINATION_DEFAULT_VALUE.PAGE_SIZE,
    total: 0,
  }
});

export default function companyHolidayReducer(state = initState(), action) {
  let { type, payload } = action;
  switch (type) {
    // Get my holiday requests (for users)
    case actions.GET_MY_REQUEST_HOLIDAYS:
      return { ...state, loading: true };
    case actions.GET_MY_REQUEST_HOLIDAYS_SUCCESS:
      return {
        ...state,
        myRequests: {
          ...state.myRequests,
          loading: false,
          errors: {},
          holidays: payload.result,
          current: payload.offset / payload.limit + 1,
          pageSize: payload.limit,
          total: payload.count,
        }
      };
    case actions.GET_MY_REQUEST_HOLIDAYS_FAIL:
      return {
        ...state,
        myRequests: {
          ...state.myRequests,
          loading: false,
          errors: payload.error,
        }
      };

    // Get holiday requests (for admin)
    case actions.GET_REQUEST_HOLIDAYS:
      return { ...state, loading: true };
    case actions.GET_REQUEST_HOLIDAYS_SUCCESS:
      return {
        ...state,
        adminRequests: {
          ...state.adminRequests,
          loading: false,
          errors: {},
          holidays: payload.result,
          current: payload.offset / payload.limit + 1,
          pageSize: payload.limit,
          total: payload.count,
        }
      };
    case actions.GET_REQUEST_HOLIDAYS_FAIL:
      return {
        ...state,
        adminRequests: {
          ...state.adminRequests,
          loading: false,
          errors: payload.error,
        }
      };
    default:
      return state;
  }
}
