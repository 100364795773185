import { call, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { notification } from "antd";
import requestHolidayApi from "api/requestHolidayApi";

function* getRequestHolidays({ payload }) {
  try {
    const response = yield call(_getRequestHolidays.bind(this, payload.filters));
    yield put({
      type: actions.GET_REQUEST_HOLIDAYS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: actions.GET_REQUEST_HOLIDAYS_FAIL,
      payload: { error },
    });
    notification.error({ message: "Get request holidays failed!" });
  }
}

function _getRequestHolidays(filters) {
  return requestHolidayApi.getRequestHolidays(filters).then((response) => {
    return response;
  });
}

function* getMyRequestHolidays({ payload }) {
  try {
    const response = yield call(_getMyRequestHolidays.bind(this, payload.filters));
    yield put({
      type: actions.GET_MY_REQUEST_HOLIDAYS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: actions.GET_MY_REQUEST_HOLIDAYS_FAIL,
      payload: { error },
    });
    notification.error({ message: "Get request holidays failed!" });
  }
}

function _getMyRequestHolidays(filters) {
  return requestHolidayApi.getMyRequestHolidays(filters).then((response) => {
    return response;
  });
}

export default function* watchRequestHolidayAsync() {
  yield takeLatest(actions.GET_REQUEST_HOLIDAYS, getRequestHolidays);
  yield takeLatest(actions.GET_MY_REQUEST_HOLIDAYS, getMyRequestHolidays);
}
