import actions from "./actions";
import cons from "../../constants";
let initState = () => ({
  loading: false,
  holidays: [],
  errors: {},

  // Pagination
  current: cons.PAGINATION_DEFAULT_VALUE.CURRENT,
  pageSize: cons.PAGINATION_DEFAULT_VALUE.PAGE_SIZE,
  total: 0,
});

export default function companyHolidayReducer(state = initState(), action) {
  let { type, payload } = action;
  switch (type) {
    // Get company holidays
    case actions.GET_COMPANY_HOLIDAYS:
      return { ...state, loading: true };
    case actions.GET_COMPANY_HOLIDAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        holidays: payload.result,
        current: payload.offset / payload.limit + 1,
        pageSize: payload.limit,
        total: payload.count,
      };
    case actions.GET_COMPANY_HOLIDAYS_FAIL:
      return {
        ...state,
        loading: false,
        errors: payload.error,
      };

    default:
      return state;
  }
}
