import ERRORS from "./errorsMessage";

const LANGUAGE_CODE = {
  ENGLISH: "en",
  JAPANESE: "jp",
};

const VALIDATE_COMMON = {
  PASSWORD: {
    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,32}$/,
    message:
      "Password including UPPER, lowercase, symbol and numbers. Character lengths from 6 to 32.",
  },
};

const PAGINATION_DEFAULT_VALUE = {
  CURRENT: 1,
  PAGE_SIZE: 10,
};

const ADD_OR_UPDATE_USER = {
  UPDATE: "UPDATE",
  ADD: "ADD",
};

const WORKING_TIME = {
  CHECK_IN: "CHECK_IN",
  CHECK_OUT: "CHECK_OUT",
};

const ROLES = {
  ADMIN: "ADMIN",
  MEMBER: "MEMBER",
};

const HOLIDAY_REQUEST_STATUS = {
  PLANNING: "Planning",
  UNAPPROVED: "Unapproved",
  APPROVED: "Approved",
  DISAPPROVED: "Disapproved",
};

const ERRORS_MESSAGE = ERRORS.ERRORS_MESSAGE;

export default {
  // Cookies key
  CK_TOKEN: "CK_TOKEN",
  CK_USER: "CK_USER",
  CK_DATA: "CK_DATA",

  //MULTI LANG
  LANG: "MULTI_LANG",

  LANGUAGE_CODE,
  ERRORS_MESSAGE,
  VALIDATE_COMMON,
  PAGINATION_DEFAULT_VALUE,
  ADD_OR_UPDATE_USER,
  WORKING_TIME,
  ROLES,

  HOLIDAY_REQUEST_STATUS,
};
