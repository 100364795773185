import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import usersApi from "../../../api/usersApi";

import AuthHelper from "../../../helpers/AuthHelper";
import {
  getUserFullName,
  getFirstWordOfName,
} from "../../../helpers/UtilsHelper";

import { logout } from "../../../redux/auth/actions";
import { getUserInfoById } from "../../../redux/common/actions";

import {
  Layout,
  Row,
  Col,
  Dropdown,
  Button,
  Avatar,
  Menu,
  notification,
} from "antd";

import Loading from "../../../components/Loading";
import CheckInOut from "../../CheckInOut";
import FirstLoginModal from "../FirstLoginModal";

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";

const Header = (props) => {
  const { toggle, collapsed } = props;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.common.loading);
  const userInfoData = useSelector((state) => state.common.userInfoData);
  const { avatar, id, isFirstLogin } = userInfoData;

  // Load data user
  useEffect(() => {
    const { user_id } = AuthHelper.getUserInfo();
    dispatch(getUserInfoById(user_id));

    // Update first login to false
    if (id && isFirstLogin) {
      updateToOffFistLogin();
    }
    // eslint-disable-next-line
  }, [id, isFirstLogin, dispatch]);

  const updateToOffFistLogin = async () => {
    await usersApi.updateFistLoginByUserId(id).catch((err) => {
      notification.warning({
        message: "Have some error when update your first login flag!",
      });
    });
  };

  const contentDropdown = (
    <Menu style={{ padding: 10, width: 160 }}>
      <span className="sm-none">
        {userInfoData ? getUserFullName(userInfoData) : ""}
      </span>
      <Menu.Divider className="sm-none" />
      <Menu.Item>
        <Link to={`${props.rolePath}/my-profile`}>My Profile</Link>
      </Menu.Item>

      <Menu.Item onClick={() => dispatch(logout())}>
        <LogoutOutlined />
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <FirstLoginModal visible={userInfoData && isFirstLogin} {...props} />
      <Layout.Header
        className="site-layout-background"
        style={{ paddingLeft: 0, paddingRight: 15 }}
      >
        {loading ? <Loading /> : ""}
        <Row>
          <Col xs={6} sm={6} md={10} lg={4} xl={4}>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: toggle,
              },
            )}
          </Col>
          <Col xs={18} sm={18} md={14} lg={20} xl={20}>
            <div style={{ position: "absolute", right: "0" }}>
              <CheckInOut isCheckedIn={userInfoData.isCheckedIn} />
              <Dropdown overlay={contentDropdown} trigger={["click"]}>
                <Button
                  size="large"
                  style={{
                    border: "none",
                    overflow: "none",
                    boxShadow: "none",
                  }}
                >
                  <Avatar
                    src={avatar}
                    style={{
                      margin: "auto",
                      backgroundColor: "#00e8ba",
                      border: "1px solid #ccc",
                    }}
                  >
                    {!avatar ? getFirstWordOfName(userInfoData) : ""}
                  </Avatar>
                  <span className="username xs-none">
                    {userInfoData ? getUserFullName(userInfoData) : ""}
                  </span>
                  <CaretDownOutlined />
                </Button>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </Layout.Header>
    </>
  );
};

export default Header;
