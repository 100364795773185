import { call, put, takeLatest } from "redux-saga/effects";
import firebase from "firebase/app";
import "firebase/auth";
import { notification } from "antd";

import actions from "./actions";
import authApi from "../../api/authApi";
import authHelper from "../../helpers/AuthHelper";

function* login(data) {
  const { email, password } = data.payload;
  try {
    const firebaseToken = yield call(firebaseAuth.bind(this, email, password));
    const data = yield call(performLogin.bind(this, { token: firebaseToken }));

    authHelper.saveToken(data.token);
    yield put({ type: actions.LOGIN_SUCCESS, payload: { data } });
  } catch (error) {
    notification.error({ message: "Login failed" });
    yield put({ type: actions.LOGIN_FAIL, payload: { error } });
  }
}

async function performLogin(firebaseToken) {
  return await authApi.login(firebaseToken);
}

async function firebaseAuth(email, password) {
  const data = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);
  let token = await data.user.getIdToken(true);
  return token;
}

function* logout(data) {
  yield call(authHelper.destroyToken.bind(this));
}

export default function* watchAuthAsync() {
  yield takeLatest(actions.LOGIN, login);
  yield takeLatest(actions.LOGOUT, logout);
}
