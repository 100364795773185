import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";

import { resetPassword } from "../../redux/resetPassword/actions";

import { Form, Input, Button, Card, Row, Col } from "antd";
import Loading from "../../components/Loading";
import ErrorSummary from "../../components/ErrorSummary";
import { LockOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import cons from "../../constants";

function ResetPassword(props) {
  const dispatch = useDispatch();

  const errors = useSelector((state) => state.resetPassword.errors);
  const loading = useSelector((state) => state.resetPassword.loading);
  const errorFromServer =
    errors && errors.response
      ? errors.response.data
      : errors.message
      ? { Null: errors.message }
      : {};

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onFinish = (values) => {
    const code = new URLSearchParams(props.location.search).get("oobCode");
    if (password && confirmPassword && code) {
      dispatch(resetPassword(code, password, redirectToLoginPage));
    }
  };

  // When reset password success, using this function by call back action
  const redirectToLoginPage = () => {
    props.history.push("/login");
  };

  return (
    <>
      {loading ? <Loading /> : ""}
      <Row justify="center" align="middle" style={{ height: "100%" }}>
        <Col span={5}>
          <Card style={{ width: "100%" }} className="box-shadow">
            <Row justify="center" align="middle" gutter={[16, 16]}>
              <Col>
                <img
                  alt="logo"
                  src={require("../../assets/images/logo.png")}
                  style={{ width: 100, textAlign: "center" }}
                />
              </Col>
            </Row>
            <Form
              layout="vertical"
              name="reset-password"
              className="reset-password-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                  cons.VALIDATE_COMMON.PASSWORD,
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  { required: true, message: "Please input your Password!" },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!",
                      );
                    },
                  }),
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Form.Item>
              <ErrorSummary error={errorFromServer} />
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", marginBottom: 15 }}
                  disabled={
                    !password ||
                    !confirmPassword ||
                    password !== confirmPassword
                  }
                >
                  Submit
                </Button>
                <Link to="/login">
                  <ArrowLeftOutlined /> Login page!
                </Link>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ResetPassword;
