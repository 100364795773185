export const firebaseConfig = {
  apiKey: "AIzaSyCoamYvVNmkiA866xcCyMKv5RRvdD1wDeo",
  authDomain: "time-mgmt-449c5.firebaseapp.com",
  databaseURL: "https://time-mgmt-449c5.firebaseio.com",
  projectId: "time-mgmt-449c5",
  storageBucket: "time-mgmt-449c5.appspot.com",
  messagingSenderId: "884093608925",
  appId: "1:884093608925:web:fa638063d214910a038c34",
  measurementId: "G-4NQ0367DBS",
};
