import { call, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { notification } from "antd";
import quoteApi from "api/quoteApi";

function* getQuotes({ payload }) {
  try {
    const response = yield call(_getQuotes.bind(this, payload.filters));
    yield put({
      type: actions.GET_QUOTES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: actions.GET_QUOTES_FAIL,
      payload: { error },
    });
    notification.error({ message: "Get quotes failed!" });
  }
}

function _getQuotes(filters) {
  return quoteApi.getQuotes(filters).then((response) => {
    return response;
  });
}

export default function* watchQuoteAsync() {
  yield takeLatest(actions.GET_QUOTES, getQuotes);
}
