import { Col, Modal, Row, Select, Slider } from 'antd';
import Loading from 'components/Loading';
import React, { useEffect, useState } from 'react';
import './WorkDetailsModal.scss';
import { useSelector } from 'react-redux';

const defaultSelectedProjects = { first: '', second: '', third: '' };
const defaultPercentages = { first: null, second: null, third: null };

const WorkDetailsModal = ({ isVisible, details, onClose, onSubmit }) => {
  const { projects } = useSelector(state => state.projects);
  const { loading } = useSelector(state => state.workingTime);
  const activeProjects = projects.filter(project => project.isActive);

  const [initial, setInitial] = useState(false);
  const [range, setRange] = useState([0]);
  const [selectedProjects, setSelectedProjects] = useState(defaultSelectedProjects);
  const [percentages, setPercentages] = useState(defaultPercentages);

  const isRange = !!selectedProjects.first && !!selectedProjects.second && !!selectedProjects.third;

  const handleSubmit = () => {
    const data = { details: [] }
    if (selectedProjects.first && percentages.first) data.details.push({ projectId: selectedProjects.first, percentage: percentages.first });
    if (selectedProjects.second && percentages.second) data.details.push({ projectId: selectedProjects.second, percentage: percentages.second });
    if (selectedProjects.third && percentages.third) data.details.push({ projectId: selectedProjects.third, percentage: percentages.third });
    onSubmit && onSubmit(data);
    onClose && onClose();
  }

  const handleAfterChange = (value) => {
    if (isRange) {
      // We are reverting the slider
      const [firstPoint, secondPoint] = value
      setPercentages({
        first: 100 - secondPoint,
        second: secondPoint - firstPoint,
        third: firstPoint
      })
    } else {
      setPercentages({
        // We are reverting the slider
        first: 100 - value,
        second: value,
        third: null
      })
    }
  }

  useEffect(() => {
    if (!!details && details.length > 0) {
      setSelectedProjects({
        first: details[0]?.projectId || '',
        second: details[1]?.projectId || '',
        third: details[2]?.projectId || '',
      })
      setPercentages({
        first: details[0]?.percentage || null,
        second: details[1]?.percentage || null,
        third: details[2]?.percentage || null,
      })
      if (details.length === 3) {
        setRange([details[2]?.percentage, details[1]?.percentage + details[2]?.percentage])
      } else if (details.length === 2) {
        setRange([details[1]?.percentage])
      } else {
        setRange([details[0]?.percentage])
      }
      setInitial(true)
    } else {
      setSelectedProjects(defaultSelectedProjects);
      setPercentages(defaultPercentages);
      setInitial(false)
    }
  }, [isVisible, details])

  useEffect(() => {
    if (!initial) {
      if (selectedProjects.first && !selectedProjects.second && !selectedProjects.third) {
        setRange([0])
        setPercentages({
          first: 100,
          second: null,
          third: null
        })
      } else if (selectedProjects.first && selectedProjects.second && !selectedProjects.third) {
        setRange([50])
        setPercentages({
          first: 50,
          second: 50,
          third: null
        })
      } else {
        setRange([30, 70])
        setPercentages({
          first: 30,
          second: 40,
          third: 30
        })
      }
    }
  }, [selectedProjects])

  return (
    <Modal
      title='Working time details'
      visible={isVisible}
      onCancel={onClose}
      okButtonProps={{ disabled: !selectedProjects.first }}
      onOk={handleSubmit}
    >
      {loading ? <Loading /> : ""}
      {(selectedProjects.first || selectedProjects.second || selectedProjects.third) && (
        <div style={{ position: 'relative' }}>
          <Slider
            range={isRange}
            step={10}
            value={isRange ? range : range[0]}
            max={100}
            onChange={(r) => isRange ? setRange(r) : setRange([r])}
            onAfterChange={handleAfterChange}
            tooltipVisible={false}
            disabled={!selectedProjects.first || !selectedProjects.second}
            reverse
            style={{ marginBottom: 40 }}
          />
          <div className="first-project first-part-slider" style={{ width: `${percentages.first}%`, display: percentages.first ? 'block' : 'none' }}></div>
          <div className="third-project third-part-slider" style={{ width: `${percentages.third}%`, display: percentages.third ? 'block' : 'none' }}></div>
        </div>
      )}
      <Row gutter={[24, 12]}>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <div className='shape first-project'>{selectedProjects.first && (`${percentages.first} %`)}</div>
            </Col>
            <Col span={16}>
              <Select
                value={selectedProjects.first}
                style={{ width: '100%' }}
                options={(activeProjects || []).map(item => ({
                  label: `${item.name} - ${item.code}`,
                  value: item.id,
                  disabled: (item.id === selectedProjects.second) || (item.id === selectedProjects.third)
                }))}
                onChange={first => setSelectedProjects({ ...selectedProjects, first })}
                allowClear={!selectedProjects.second}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <div className='shape second-project'>{selectedProjects.second && (`${percentages.second} %`)}</div>
            </Col>
            <Col span={16}>
              <Select
                value={selectedProjects.second}
                disabled={!selectedProjects.first}
                style={{ width: '100%' }}
                options={(activeProjects || []).map(item => ({
                  label: `${item.name} - ${item.code}`,
                  value: item.id,
                  disabled: (item.id === selectedProjects.first) || (item.id === selectedProjects.third)
                }))}
                onChange={second => setSelectedProjects({ ...selectedProjects, second })}
                allowClear={!selectedProjects.third}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <div className='shape third-project'>{selectedProjects.third && (`${percentages.third} %`)}</div>
            </Col>
            <Col span={16}>
              <Select
                value={selectedProjects.third}
                disabled={!selectedProjects.first || !selectedProjects.second}
                style={{ width: '100%' }}
                options={(activeProjects || []).map(item => ({
                  label: `${item.name} - ${item.code}`,
                  value: item.id,
                  disabled: (item.id === selectedProjects.first) || (item.id === selectedProjects.second)
                }))}
                onChange={third => setSelectedProjects({ ...selectedProjects, third })}
                allowClear
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}

export default WorkDetailsModal;