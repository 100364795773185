import moment from "moment";
import _ from "lodash";
import constants from "../constants";

export const getMomentObject = (data, format = "YYYY-MM-DD HH:mm:ss") =>
  data && moment(data, format);
export const getDate = (data, format = "YYYY-MM-DD") =>
  data && moment(data).format(format);
export const getDateFormat = (data, format = "MM/DD/YYYY") =>
  data && moment(data).format(format);
export const getTime = (data, format = "LT") =>
  data && moment(data).format(format);
export const getTimeFull = (data, format = "HH:mm:ss") =>
  data && moment(data).format(format);
export const getTimeByHoursMinutesFm = (data, format = "HH:mm") =>
  data && moment(data).format(format);
export const getDateTime = (data, format = "MM/DD/YYYY hh:mm a") =>
  data && moment(data).format(format);
export const getYearOld = (data, format) =>
  data && moment().diff(moment(data), "year");
export const getDayInCurrentWeek = (day, format = "YYYY-MM-DD") =>
  moment()
    .weekday(day)
    .format(format);
export const getStartOfMonth = (format = "YYYY-MM-DD") =>
  moment()
    .startOf("month")
    .format(format);
export const getEndOfMonth = (format = "YYYY-MM-DD") =>
  moment()
    .endOf("month")
    .format(format);
export const getDayTimeString = (dateTime, format = "YYYY-MM-DD HH:mm:ss") =>
  moment(dateTime).format(format);
export const getTimezone = (dateTime, format = "YYYY-MM-DDTHH:mm:ss") =>
  moment(dateTime).format(format);

export const uppercaseFirstLetter = (data) =>
  data.charAt(0).toUpperCase() + data.slice(1);

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1
      .toUpperCase()
      .replace("-", "")
      .replace("_", "");
  });
};

const isArray = function (a) {
  return Array.isArray(a);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== "function";
};

export const getUserFullName = (user, defaultName = "Other") => {
  if (!user) {
    return defaultName;
  }
  const preferredName = user.preferredName ? `"${user.preferredName}"` : "";
  let fullName = `${preferredName} ${user.firstName || ""} ${user.middleName ||
    ""} ${user.lastName || ""}`
    .trim()
    .replace(/ +(?= )/g, "");
  const fullNameRs = _.isEmpty(fullName) ? defaultName : fullName;

  return fullNameRs;
};

export const getPortalType = () => {
  const hash = window.location.hash;
  if (hash.startsWith("#/admin")) return "admin";

  if (hash.startsWith("#/member")) return "member";
};

export const stripHtml = (content) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = content;
  const textContent = tmp.textContent || tmp.innerText || "";
  return textContent.trim();
};

export const arrayToParams = (array = []) => {
  if (!array) return
  return `key=${array.join("&key=")}`
};

export const urlQueryFromObject = (filters) => {
  if (!_.isObject || _.isNull(filters)) {
    return "";
  }

  for (let key in filters) {
    const value = String(filters[key]);
    if (_.isEmpty(value) || _.isNull(value)) {
      delete filters[key];
    }
  }

  filters = { limit: 100000, offset: 0, ...filters };

  if (!_.isUndefined(filters.desc) && !filters.desc) {
    filters.ordering = `-${filters.ordering}`.replace(/,/g, ",-");
  }

  const searchParams = new URLSearchParams(filters);
  searchParams.delete("desc");
  return "?" + searchParams.toString();
};

export const keysToCamel = function (o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i);
    });
  }

  return o;
};

export const isGTENowDay = (current) => {
  var yesterday = moment().subtract(1, "day");
  return current.isAfter(yesterday);
};

export const convertToDecimal = (data, length) => {
  const weight = length ? 10 * length : 1;
  return Math.round(data * weight) / weight;
};

export const getFirstWordOfName = (user, defaultName = "OT") => {
  if (_.isEmpty(user)) {
    return defaultName;
  }
  let fullName = `${user.firstName.slice(0, 1).toLocaleUpperCase() ||
    ""}${user.lastName.slice(0, 1).toLocaleUpperCase() || ""}`
    .trim()
    .replace(/ +(?= )/g, "");
  return _.isEmpty(fullName) ? defaultName : fullName;
};

export const getDurationTime = (startTime, endTime, unit = "hours") => {
  const duration = moment.duration(moment(endTime).diff(moment(startTime)));
  switch (unit) {
    case "days":
      return duration.asDays();
    case "hours":
      return duration.asHours();
    case "minutes":
      return duration.asMinutes();
    default:
      return duration.asMilliseconds();
  }
};

export const fixNumber = (number, amount = 2) => {
  return Number(number).toFixed(amount);
};

export const splitContent = (content, amount = 5) => {
  const splitContent = content
    .split(" ")
    .slice(0, amount)
    .join(" ");
  const showMore = content.split(" ").length > amount ? "..." : "";
  return splitContent.concat(showMore);
};

export const numericCompare = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
}).compare;

// For calendar
export const getMonthName = (date) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[date.getMonth()];
};

export const isSameDay = (date, d) => {
  return (
    date.getFullYear() === d.getFullYear() &&
    date.getMonth() === d.getMonth() &&
    date.getDate() === d.getDate()
  );
};

export const getAPIFiltersByFilters = (filters) => {
  const copyFilters = { ...filters };
  const result = {
    limit: copyFilters.pageSize,
    offset: (copyFilters.current - 1) * copyFilters.pageSize,
  };
  delete copyFilters.pageSize;
  delete copyFilters.current;
  delete copyFilters.total;
  return {
    ...result,
    ...copyFilters,
  };
};

export const formatReportData = (reportData) => {
  if (reportData && reportData.result && reportData.result.length > 0) {
    const result = [];
    const length = reportData.result.length;
    let totalActual = "00:00";
    let totalBreak = "00:00";
    for (let i = 0; i < length; i++) {
      let date = reportData.result[i].date;
      let startTime = reportData.result[i].start;
      let endTime = reportData.result[i].end;
      let breakTime = reportData.result[i].breakTime;
      let actualTime = reportData.result[i].actualTime;
      let data = {
        key: i + 1,
        day: date ? getDate(date) : "----/--/--",
        start: startTime ? getTimeByHoursMinutesFm(startTime) : "--:--",
        end: endTime ? getTimeByHoursMinutesFm(endTime) : "--:--",
        breakTime: breakTime ? breakTime.substring(0, 5) : "--:--",
        actual: actualTime ? actualTime.substring(0, 5) : "--:--",
      };
      result.push(data);
      totalActual = addTimes(totalActual, actualTime);
      totalBreak = addTimes(totalBreak, breakTime);
    }
    result.push({
      key: length + 1,
      totalBreak,
      totalActual,
    });

    return result;
  }

  return [];
};

export const formatAbsenceReportData = (holidays) => {
  if (holidays && holidays.length > 0) {
    const result = holidays.map((item, index) => ({
      ...item,
      key: index + 1,
      isTotal: false,
      isAllTotal: false
    }))
    const length = holidays.length
    const totalDays = holidays.filter(_ => _.status !== constants.HOLIDAY_REQUEST_STATUS.DISAPPROVED).reduce((value, item) => value + item.workDays, 0)
    const allTotalDays = holidays.reduce((value, item) => value + item.workDays, 0)
    result.push(...[
      {
        key: length + 1,
        totalDays,
        isTotal: true,
        isAllTotal: false
      },
      {
        key: length + 2,
        allTotalDays,
        isTotal: false,
        isAllTotal: true
      }
    ])
    return result
  }
  return []
};

export const formatProjectReportData = (projects) => {
  if (projects && projects.length > 0) {
    const result = projects.map((item, index) => ({
      ...item,
      key: index + 1,
      isTotal: false
    }))
    const length = projects.length
    const totalHours = projects.reduce((value, item) => value + item.workedHours, 0)
    result.push(...[
      {
        key: length + 1,
        totalHours,
        isTotal: true
      }
    ])
    return result
  }
  return []
};

export const addTimes = (currentTime, addNewTime) => {
  if (addNewTime) {
    const addTimeSplited = addNewTime.split(":");
    const addHours = parseInt(addTimeSplited[0]);
    const addMinutes = parseInt(addTimeSplited[1]);

    const currentTimeSplited = currentTime.split(":");
    const currentHours = parseInt(currentTimeSplited[0]);
    const currentMinutes = parseInt(currentTimeSplited[1]);

    let totalHours = addHours + currentHours;
    let totalMinutes = addMinutes + currentMinutes;

    if (totalMinutes > 60) {
      let hoursOfMinutes = Math.floor(totalMinutes / 60);
      totalMinutes = totalMinutes - hoursOfMinutes * 60;
      totalHours += hoursOfMinutes;
    }

    return (
      (totalHours >= 10 ? totalHours : `0${totalHours}`) +
      ":" +
      (totalMinutes >= 10 ? totalMinutes : `0${totalMinutes}`)
    );
  }
  return currentTime;
};

export const getContentGlobalMsgs = (globalMsgs) => {
  let result = "";
  if (globalMsgs && globalMsgs.result && globalMsgs.result.length > 0) {
    result = globalMsgs.result[0].message;
  }
  return result;
};

export const hoursToTimeString = val => {
  const hours = Math.floor(val)
  const minutes = Math.floor((val - hours) * 60)
  const hourString = hours >= 10 ? hours : `0${hours}`
  const minuteString = minutes >= 10 ? minutes : `0${minutes}`
  return `${hourString}:${minuteString}`;
}