import { call, put, takeLatest } from "redux-saga/effects";

import actions from "./actions";
import userApi from "../../api/usersApi";
import globalMessagesApi from "../../api/globalMessagesApi";

import { notification } from "antd";

function* getUserInfoById(data) {
  try {
    const userInfoData = yield call(_getUserInfoById.bind(this, data.payload));

    yield put({
      type: actions.GET_USER_INFO_BY_ID_SUCCESS,
      payload: { userInfoData },
    });
  } catch (error) {
    yield put({
      type: actions.GET_USER_INFO_BY_ID_FAIL,
      payload: { error },
    });
    notification.error({ message: "Get user information failed!" });
  }
}

function _getUserInfoById(payload) {
  return userApi.getUserInfoById(payload.userId).then((response) => {
    return response;
  });
}

function* getGlobalMessages() {
  try {
    const globalMessages = yield call(_getGlobalMessages.bind(this));

    yield put({
      type: actions.GET_GLOBAL_MESSAGES_SUCCESS,
      payload: { globalMessages },
    });
  } catch (error) {
    yield put({
      type: actions.GET_GLOBAL_MESSAGES_FAIL,
      payload: { error },
    });
    notification.error({ message: "Get global messages failed!" });
  }
}

function _getGlobalMessages() {
  return globalMessagesApi.getGlobalMessages().then((response) => {
    return response;
  });
}

function* updateGlobalMessages(data) {
  try {
    yield call(_updateGlobalMessages.bind(this, data.payload));
    yield put({
      type: actions.GET_GLOBAL_MESSAGES,
    });
    yield put({
      type: actions.UPDATE_GLOBAL_MESSAGES_SUCCESS,
    });
    notification.success({ message: "Update messages success!" });
  } catch (error) {
    yield put({
      type: actions.UPDATE_GLOBAL_MESSAGES_FAIL,
      payload: { error },
    });
    notification.error({ message: "Update messages failed!" });
  }
}

function _updateGlobalMessages(data) {
  // if exists, update message
  if (data.messages.id) {
    return globalMessagesApi
      .updateGlobalMessages(data.messages)
      .then((response) => {
        return response;
      });
  } else {
    // Add new msg
    return globalMessagesApi
      .addGlobalMessage(data.messages)
      .then((response) => {
        return response;
      });
  }
}

export default function* watchCommonAsync() {
  yield takeLatest(actions.GET_USER_INFO_BY_ID, getUserInfoById);
  yield takeLatest(actions.GET_GLOBAL_MESSAGES, getGlobalMessages);
  yield takeLatest(actions.UPDATE_GLOBAL_MESSAGES, updateGlobalMessages);
}
