import { call, put, takeLatest } from "redux-saga/effects";
import firebase from "firebase/app";
import "firebase/auth";
import { notification } from "antd";

import actions from "./actions";
import passwordApi from "../../api/passwordApi";

function* requestResetPassword(data) {
  try {
    const payload = yield call(_requestResetPassword.bind(this, data.payload));

    yield put({
      type: actions.REQUEST_RESET_PASSWORD_SUCCESS,
      payload,
    });
    notification.success({ message: "Please check your email!" });
  } catch (error) {
    yield put({
      type: actions.REQUEST_RESET_PASSWORD_FAIL,
      payload: { error },
    });
    notification.error({ message: "Send mail to reset password failed" });
  }
}

function _requestResetPassword(payload) {
  return passwordApi.requestResetPassword(payload).then((response) => {
    return response;
  });
}

function* resetPassword(data) {
  try {
    const { code, password, callback } = data.payload;
    const payload = yield call(_resetPassword.bind(this, code, password));
    yield put({
      type: actions.RESET_PASSWORD_SUCCESS,
      payload,
    });
    callback();
    notification.success({
      message: "Reset password success! Please login again!",
    });
  } catch (error) {
    yield put({
      type: actions.RESET_PASSWORD_FAIL,
      payload: { error },
    });
    notification.error({ message: "Reset password failed!" });
  }
}

async function _resetPassword(code, newPassword) {
  let response = await firebase.auth().confirmPasswordReset(code, newPassword);
  return response;
}

export default function* watchResetPasswordAsync() {
  yield takeLatest(actions.REQUEST_RESET_PASSWORD, requestResetPassword);
  yield takeLatest(actions.RESET_PASSWORD, resetPassword);
}
