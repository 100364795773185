const actions = {
  REQUEST_RESET_PASSWORD: "resetPassword/REQUEST_RESET_PASSWORD",
  REQUEST_RESET_PASSWORD_SUCCESS:
    "resetPassword/REQUEST_RESET_PASSWORD_SUCCESS",
  REQUEST_RESET_PASSWORD_FAIL: "resetPassword/REQUEST_RESET_PASSWORD_FAIL",

  RESET_PASSWORD: "resetPassword/RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "resetPassword/RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAIL: "resetPassword/RESET_PASSWORD_FAIL",
};

export default actions;

export const requestResetPassword = (email) => ({
  type: actions.REQUEST_RESET_PASSWORD,
  payload: { email },
});

export const resetPassword = (code, password, callback) => ({
  type: actions.RESET_PASSWORD,
  payload: { code, password, callback },
});
