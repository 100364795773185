import axios from "axios";
import { arrayToParams, urlQueryFromObject } from "../helpers/UtilsHelper";

const clientPath = `/clients`;

export default {
  async getClients(filters) {
    const path = `${clientPath}${urlQueryFromObject(filters)}`;
    const response = await axios.get(path);
    return response.data;
  },
  async createClient(data) {
    const response = await axios.post(clientPath, data);
    return response.data;
  },
  // async updateClient(id, data) {
  //   const path = `${clientPath}/${id}`;
  //   const response = await axios.put(path, data);
  //   return response.data;
  // },
  // async deleteClient(id) {
  //   const path = `${clientPath}/${id}`;
  //   const response = await axios.delete(path);
  //   return response.data;
  // },
  async deleteClients(ids) {
    const path = `${clientPath}/delete-ids?${arrayToParams(ids)}`;
    const response = await axios.delete(path);
    return response.data;
  },
};
