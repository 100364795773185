import { call, put, takeLatest } from "redux-saga/effects";

import actions from "./actions";
import workingTimeActions from "../workingTime/actions";
import workingTimeApi from "../../api/workingTimeApi";

import { notification } from "antd";
import cons from "../../constants";

function* checkIn() {
  try {
    yield call(_checkIn.bind(this));
    yield put({ type: actions.CHECK_IN_SUCCESS });
    // Update current status, and total working time
    yield put({ type: workingTimeActions.GET_CURRENT_STATUS });
    yield put({ type: workingTimeActions.GET_TOTAL_WORKING_TIME });
  } catch (error) {
    yield put({ type: actions.CHECK_IN_FAIL, payload: { error } });
    notification.error({ message: error?.response?.data?.error || "Checkin error!" });
  }
}

function _checkIn() {
  return workingTimeApi.checkIn().then((response) => {
    return response;
  });
}

function* checkOut({ payload }) {
  try {
    yield call(_checkOut.bind(this, payload));
    yield put({ type: actions.CHECK_OUT_SUCCESS });
    // Update current status, and total working time
    yield put({ type: workingTimeActions.GET_CURRENT_STATUS });
    yield put({ type: workingTimeActions.GET_TOTAL_WORKING_TIME });
  } catch (error) {
    yield put({ type: actions.CHECK_OUT_FAIL, payload: { error } });
    notification.error({ message: error?.response?.data?.error || "Checkout error!" });
  }
}

function _checkOut(data) {
  return workingTimeApi.checkOut(data).then((response) => {
    return response;
  });
}

function* getCurrentStatus() {
  try {
    const currentStatus = yield call(_getCurrentStatus.bind(this));

    yield put({
      type: actions.GET_CURRENT_STATUS_SUCCESS,
      payload: { currentStatus },
    });
  } catch (error) {
    yield put({
      type: actions.GET_CURRENT_STATUS_FAIL,
      payload: { error },
    });
    notification.error({ message: "Get current status error!" });
  }
}

function _getCurrentStatus() {
  return workingTimeApi.getCurrentStatusToday().then((response) => {
    return response;
  });
}

function* getMonthlyOverview() {
  try {
    const data = yield call(_getMonthlyOverview.bind(this));
    yield put({
      type: actions.GET_MONTHLY_OVERVIEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.GET_MONTHLY_OVERVIEW_FAIL,
      payload: { error },
    });
    notification.error({
      message: "Get monthly overview error!",
    });
  }
}

function _getMonthlyOverview() {
  return workingTimeApi.getMonthlyOverview().then((response) => {
    return response;
  });
}

function* getTotalWorkingTime() {
  try {
    const totalWorkingTime = yield call(_getTotalWorkingTime.bind(this));
    yield put({
      type: actions.GET_TOTAL_WORKING_TIME_SUCCESS,
      payload: { totalWorkingTime },
    });
  } catch (error) {
    yield put({
      type: actions.GET_TOTAL_WORKING_TIME_FAIL,
      payload: { error },
    });
    notification.error({
      message: "Get total working time of this month error!",
    });
  }
}

function _getTotalWorkingTime() {
  return workingTimeApi.getTotalWorkingTime().then((response) => {
    return response;
  });
}

function* getCheckedInStatus() {
  try {
    const response = yield call(_getCheckedInStatus.bind(this));
    const isCheckedIn = response && response.checkIn ? !response.checkOut : false;
    yield put({
      type: actions.GET_CHECKED_IN_STATUS_SUCCESS,
      payload: { isCheckedIn, lastCheckIn: response.checkIn },
    });
  } catch (error) {
    yield put({
      type: actions.GET_CHECKED_IN_STATUS_FAIL,
      payload: { error },
    });
    notification.error({
      message: "Get checked in status error!",
    });
  }
}

function _getCheckedInStatus() {
  return workingTimeApi.getCheckingStatus().then((response) => {
    return response;
  });
}

function* getReport(data) {
  try {
    const reportData = yield call(
      _getReport.bind(this, data.payload.role, data.payload.filters),
    );
    yield put({
      type: actions.GET_REPORT_SUCCESS,
      payload: { reportData },
    });
  } catch (error) {
    yield put({
      type: actions.GET_REPORT_FAIL,
      payload: { error },
    });
    notification.error({
      message: "Get report data failed!",
    });
  }
}

function _getReport(role, filters) {
  if (role === cons.ROLES.ADMIN) {
    return workingTimeApi.getReportByAdmin(filters).then((response) => {
      return response;
    });
  }
  // Else get report by member
  return workingTimeApi.getReportByMember(filters).then((response) => {
    return response;
  });
}

function* getCheckInOutUsersThatDay() {
  try {
    const checkInOutUsersThatDayData = yield call(
      _getCheckInOutUsersThatDay.bind(this),
    );
    yield put({
      type: actions.GET_CHECK_IN_OUT_USERS_THAT_DAY_SUCCESS,
      payload: { checkInOutUsersThatDayData },
    });
  } catch (error) {
    yield put({
      type: actions.GET_CHECK_IN_OUT_USERS_THAT_DAY_FAIL,
      payload: { error },
    });
    notification.error({
      message: "Get check in/out information of users today failed!",
    });
  }
}

function _getCheckInOutUsersThatDay() {
  return workingTimeApi.getCheckInOutUsersThatDay().then((response) => {
    return response;
  });
}

function* getCalendars() {
  try {
    const response = yield call(_getCalendars.bind(this));
    yield put({
      type: actions.GET_CALENDARS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: actions.GET_CALENDARS_FAIL,
      payload: { error },
    });
    notification.error({
      message: "Get calendars failed!",
    });
  }
}

function _getCalendars() {
  return workingTimeApi.getCalendars().then((response) => {
    return response;
  });
}

export default function* watchWorkingTimeAsync() {
  yield takeLatest(actions.CHECK_IN, checkIn);
  yield takeLatest(actions.CHECK_OUT, checkOut);
  yield takeLatest(actions.GET_CURRENT_STATUS, getCurrentStatus);
  yield takeLatest(actions.GET_MONTHLY_OVERVIEW, getMonthlyOverview);
  yield takeLatest(actions.GET_TOTAL_WORKING_TIME, getTotalWorkingTime);
  yield takeLatest(actions.GET_CHECKED_IN_STATUS, getCheckedInStatus);
  yield takeLatest(actions.GET_REPORT, getReport);
  yield takeLatest(actions.GET_CHECK_IN_OUT_USERS_THAT_DAY, getCheckInOutUsersThatDay);
  yield takeLatest(actions.GET_CALENDARS, getCalendars);
}
