import { call, put, takeLatest } from "redux-saga/effects";

import actions from "./actions";

import { notification } from "antd";
import companyHolidayApi from "api/companyHolidayApi";

function* getAllCompanyHolidays({ payload }) {
  try {
    const response = yield call(_getAllCompanyHolidays.bind(this, payload.filters));
    yield put({
      type: actions.GET_COMPANY_HOLIDAYS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: actions.GET_COMPANY_HOLIDAYS_FAIL,
      payload: { error },
    });
    notification.error({ message: "Get company holidays failed!" });
  }
}

function _getAllCompanyHolidays(filters) {
  return companyHolidayApi.getCompanyHolidays(filters).then((response) => {
    return response;
  });
}

export default function* watchCompanyHolidayAsync() {
  yield takeLatest(actions.GET_COMPANY_HOLIDAYS, getAllCompanyHolidays);
}
